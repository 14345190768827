import React from "react";
import DbfPage from "../features/dbf/DbfPage";

function ConversieDBF() {
  return (
    <>
      <DbfPage />
    </>
  );
}

export default ConversieDBF;
