import { Outlet, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
  PoweroffOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useSendLogoutMutation } from "../features/auth/authApiSlice";
import { useGetFirmeQuery } from "../features/firme/firmeApiSlice";
import useAuth from "../hooks/useAuth";

const { Header, Sider, Content } = Layout;

function DefaultLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const [firmeData, setFirmeData] = useState([]);

  const navigate = useNavigate();

  const { id } = useAuth();

  const [sendLogout] = useSendLogoutMutation();

  const {
    data: firme,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetFirmeQuery(id);

  const getFirme = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(firme?.entities).map(
        (key) => firme.entities[key]
      );
      setFirmeData(result);
    }
  };

  useEffect(() => {
    getFirme();
    // eslint-disable-next-line
  }, [firme]);

  let firmeMenu = [];

  firmeData.forEach((item) => {
    firmeMenu.push({
      key: `/extract/${id}/${item.id}`,
      label: item.numeFirma,
    });
  });

  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        height: "100vh",
      }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          paddingTop: 10,
        }}
      >
        <div style={{ height: "60px" }}></div>

        <Menu
          onClick={({ key }) => {
            navigate(key);
          }}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={[
            {
              key: "/user",
              icon: <SettingOutlined />,
              label: "Setari",
            },

            {
              key: "Firme",
              icon: <AppstoreOutlined />,
              label: "Firme",
              children: firmeMenu,
            },

            {
              key: "/dbf",
              icon: <AppstoreOutlined />,
              label: "Modificare BDF",
            },

            {
              icon: <PoweroffOutlined />,
              label: "Deconectare",
              danger: true,
              onClick: sendLogout,
              key: "/",
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            paddingLeft: 20,
            color: "white",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            marginRight: "24px",
            marginLeft: "24px",
            marginTop: "12px",
            marginBottom: "12px",
            paddingRight: 24,
            paddingLeft: 24,
            minHeight: 280,
          }}
        >
          <div
            style={{
              overflowY: "scroll",
              height: "100%",
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default DefaultLayout;
