import React from "react";
import "./App.css";
import DefaultLayout from "./components/DefaultLayout";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./features/auth/Login";
import Register from "./features/auth/Register";
import Homepage from "./features/auth/Homepage";
import Setari from "./pages/Setari";
import ConversieDBF from "./pages/ConversieDBF";
import ExtractiiPage from "./features/extractii/ExtractiiPage";
import FirmeList from "./features/firme/FirmeList";
import FirmaNou from "./features/firme/FirmaNou";
import EditFirma from "./features/firme/EditFirma";

import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
// import { ROLES } from "./config/roles";
import useTitle from "./hooks/useTitle";

function App() {
  useTitle("Contabilitate");

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          {/* <Route index element={<Login />} /> */}
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />

          {/* Protected Routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route element={<Prefetch />}>
                <Route path="/" element={<DefaultLayout />}>
                  <Route index element={<Homepage />} />

                  <Route path="firme">
                    <Route index element={<FirmeList />} />
                    <Route path=":idFirma" element={<EditFirma />} />
                    <Route path="noua" element={<FirmaNou />} />
                  </Route>

                  <Route path="extract/">
                    <Route index element={<ExtractiiPage />} />
                    <Route
                      path=":idUser/:idFirma"
                      element={<ExtractiiPage />}
                    />
                  </Route>

                  <Route path="user">
                    <Route index element={<Setari />} />
                  </Route>

                  <Route path="dbf">
                    <Route index element={<ConversieDBF />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* End Protected Routes */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
