import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx";
import { uploadDBF } from "../../app/axios";
import { Button, Spin } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";

function ModifyDbf({ id, fileName, setCurrent, setDownloadLink }) {
  const [furnizoriMegaPub, setFurnizoriMegaPub] = useState([]);
  const [furnizoriConta, setFurnizoriConta] = useState([]);
  const [error, setError] = useState(null);
  const [arrError, setArrError] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const ref = useRef();
  const ref2 = useRef();

  let txt = <>Va rugam asteptati!</>;

  const nrRandHeader = 1; // la ce rand este capul de tabel

  function cautareFurnizori(furnizori, cif) {
    let cautareFurnizor = furnizori?.find(
      (e) => e[2].replace(/\D/g, "") === cif
    );
    if (cautareFurnizor === undefined) {
      setError("eroare");

      if (!arrError.includes(cif)) {
        setArrError([...arrError, cif]);
      }
    } else {
      return cautareFurnizor[1];
    }
  }

  const readExcelMegaPub = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        var range = XLSX.utils.decode_range(ws["!ref"]);
        range.s.r = nrRandHeader; // <-- zero-indexed, so setting to 1 will skip row 0
        ws["!ref"] = XLSX.utils.encode_range(range);

        const data = XLSX.utils.sheet_to_json(ws, { header: nrRandHeader });

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setFurnizoriMegaPub(d);
    });
  };

  const readExcelConta = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        var range = XLSX.utils.decode_range(ws["!ref"]);
        range.s.r = nrRandHeader; // <-- zero-indexed, so setting to 1 will skip row 0
        ws["!ref"] = XLSX.utils.encode_range(range);

        const data = XLSX.utils.sheet_to_json(ws, { header: nrRandHeader });

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setFurnizoriConta(d);
    });
  };

  useEffect(() => {
    setError(null);
    if (furnizoriMegaPub.length !== 0) {
      furnizoriMegaPub.forEach((e) => {
        const cui = e[2].replace(/\D/g, "");
        cautareFurnizori(furnizoriConta, cui);
      });
    }
  }, [furnizoriConta, arrError]);

  const handleSubmit = async () => {
    setLoading(true);

    const getFisierDBF = await uploadDBF({
      id,
      fileName,
      furnizoriMegaPub,
      furnizoriConta,
    });

    if (getFisierDBF.data === "eroare") {
      console.log("eroare");
    } else {
      setDownloadLink(getFisierDBF.data);
      setCurrent(2);
    }

    setLoading(false);
  };

  let content;

  content = (
    <>
      <Spin tip={txt} size="large" spinning={isLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>Incarca fisierele excel cu furnizorii din saga</div>
          <div style={{ marginTop: 14, marginBottom: 14 }}>
            <div>MegaPub Furnizori:</div>
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                const file = e.target.files[0];
                readExcelMegaPub(file);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              style={{
                backgroundColor: "white",
                marginTop: "3px",
                cursor: "pointer",
              }}
              ref={ref}
            />
          </div>

          <div>
            <div>Contabil Furnizori:</div>
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                const file = e.target.files[0];
                readExcelConta(file);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              style={{
                backgroundColor: "white",
                marginTop: "3px",
                cursor: "pointer",
              }}
              ref={ref2}
            />
          </div>

          {error && (
            <div style={{ color: "red", maxWidth: 600, marginTop: 14 }}>
              Eroare! Furnizorul/furnizorii:{" "}
              {arrError.map((item, i) => (
                <span key={i}>{item}, </span>
              ))}{" "}
              nu exista in programul dvs. SAGA! Introduceti furnizorii mai intai
              in Saga si dupa incarcati fisierul din nou!
            </div>
          )}

          {!error &&
            furnizoriConta.length !== 0 &&
            furnizoriMegaPub.length !== 0 && (
              <>
                <Button
                  icon={<CloudDownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px", marginTop: 14 }}
                  onClick={handleSubmit}
                >
                  Modifica DBF
                </Button>
              </>
            )}
        </div>
      </Spin>
    </>
  );

  return content;
}

export default ModifyDbf;
