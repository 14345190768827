import axios from "axios";
import { apiUrl } from "./url";

export const singleFileUpload = async (data, options) => {
  try {
    await axios.post(`${apiUrl}/dbf`, data, options);
  } catch (error) {
    throw error;
  }
};

export const uploadDBF = async (params) => {
  let payload = {
    id: params.id,
    fileName: params.fileName,
    furnizoriMegaPub: params.furnizoriMegaPub,
    furnizoriConta: params.furnizoriConta,
  };

  try {
    const { data } = await axios.post(`${apiUrl}/dbf/modify`, payload);
    return {
      data: data,
      mesaj: "Extragerea datelor finalizata!",
      color: "green",
    };
  } catch (error) {
    return { data: [], mesaj: error.response.data.message, color: "red" };
  }
};
