import React, { useState, useEffect } from "react";
import { singleFileUpload } from "../../app/axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function UploadDbfs({ id, setCurrent, setFileName }) {
  const [singleFile, setSingleFile] = useState();
  const [singleProgress, setSingleProgress] = useState(0);

  const SingleFileChange = (e) => {
    setSingleFile(e.target.files[0]);
    setSingleProgress(0);
  };

  const singleFileOptions = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
      setSingleProgress(percentage);
    },
  };

  const uploadSingleFile = async () => {
    const formData = new FormData();
    formData.append("idUser", id);
    formData.append("title", "dbf-file");
    formData.append("file", singleFile);
    await singleFileUpload(formData, singleFileOptions);
    setCurrent(1);
    setFileName(singleFile.name);
  };

  useEffect(() => {
    if (singleFile !== undefined) uploadSingleFile();
  }, [singleFile]);

  const content = (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Incarca fisierul .dbf</div>

        <input
          type="file"
          style={{
            marginTop: "7px",
          }}
          onChange={(e) => SingleFileChange(e)}
          accept=".dbf"
        />

        <div
          style={{
            marginTop: "5px",
            width: 100,
          }}
        >
          <CircularProgressbar
            value={singleProgress}
            text={`${singleProgress}%`}
            styles={buildStyles({
              rotation: 0.25,
              strokeLinecap: "butt",
              textSize: "16px",
              pathTransitionDuration: 0.5,
              pathColor: `rgba(255, 136, 136, ${singleProgress / 100})`,
              textColor: "#f88",
              trailColor: "#d6d6d6",
              backgroundColor: "#3e98c7",
            })}
          />
        </div>
      </div>
    </>
  );

  return content;
}

export default UploadDbfs;
