import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import UploadDbfs from "./UploadDbf";
import ModifyDbf from "./ModifyDbf";
import { Button, Steps } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { apiUrl } from "../../app/url";

const steps = [
  {
    title: "Fisier DBF",
  },
  {
    title: "Fisiere furnizori",
  },
  {
    title: "Descarcare DBF final",
  },
];

function DbfPage() {
  const [current, setCurrent] = useState(0);
  const [fileName, setFileName] = useState("");
  const [downloadLink, setDownloadLink] = useState("");

  const { id } = useAuth();

  const downloadDbfFile = () => {
    fetch(`${apiUrl}/${downloadLink}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `modificat-${fileName}` || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  let content;

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  content = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "50px",
            width: "700px",
            alignItems: "center",
            border: "solid 1px",
            borderRadius: "20px",
            padding: "20px",
            backgroundColor: "white",
          }}
        >
          <strong style={{ marginBottom: "20px", backgroundColor: "white" }}>
            Modificare coduri furnizori
          </strong>

          <Steps size="small" current={current} items={items} />
          <div style={{ marginTop: 24 }}>
            {current === 0 && (
              <UploadDbfs
                id={id}
                setCurrent={setCurrent}
                setFileName={setFileName}
              />
            )}
            {current === 1 && (
              <ModifyDbf
                id={id}
                fileName={fileName}
                setCurrent={setCurrent}
                setDownloadLink={setDownloadLink}
              />
            )}
            {current === 2 && (
              <>
                <Button
                  icon={<DownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px" }}
                  type="link"
                  key="descarca2"
                  onClick={() => downloadDbfFile()}
                >
                  Descarca DBF modificat
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return content;
}

export default DbfPage;
